export const RequiredFieldErrorMessage = 'Dies ist ein Pflichtfeld. ';
export const RequiredMitgliederNumberErrorMessage = 'Wert darf nicht Null sein'
export const LoaderMessage = 'Bitte warten, die App arbeitet für Sie. ';
export const RegistrationFormLoaderMessage = 'Wir richten alles für Sie ein, das kann ein bis zwei Minuten dauern. Danke für Ihre Geduld. ';
export const RegistrationUnsuccessfulMessage = 'Da hat etwas nicht gelappt. Bitte versuche es nochmal. ';
export const NeueSitzungDisabledGremiumMembersMissingInConfig = 'Sie können nur Sitzungen erstellen, wenn die Konfiguration vollständig ist. Bitte überprüfen Sie, ob Sie alle Mitglieder ausgewählt haben.';
export const NeueSitzungDisabledNoPermissions = 'Sie sind als ordentliches Mitglied nur berechtigt, Sitzungen zu erstellen, wenn Sie über Schreibrechte verfügen.';
export const SitzungSameNameErrorMessage = "Es gibt bereits eine Sitzung mit diesem Namen, bitte vergib einen anderen Namen. ";
export const AuschussSameNameErrorMessage = "Es gibt bereits eine Auschuss mit diesem Namen, bitte vergib einen anderen Namen. ";
export const SitzungLongNameErrorMessage = "Der Name der Sitzung ist zu lang. ";
export const SitzungNameIllegalCharsErrorMessage = `Die folgenden Zeichen werden nicht unterstützt: ~#%&*{}+/\:<>?|'". . `;
export const SitzungEndMustBeAfterStartErrorMessage = "Ende der Sitzung muss nach dem Beginn liegen. ";
export const ConfirmResetToDefault = "Sind Sie sicher, dass Sie die Standardeinstellungen wiederherstellen möchten?";
export const ConfirmResetToDefaultTagesordnung = "Sind Sie sicher, dass Sie die Tagesordnung auf die Ursprungseinstellungen der App zurücksetzen möchten?";
export const AbsenceReasonNeeded = "Wähle bitte einen Verhinderungsgrund aus."
export const TooltipAttendanceList = "Das Mitglied hat noch nicht über das Rückmeldeformular geantwortet. Sollte es auf anderem Weg Bescheid gegeben haben, können Sie hier die Rückmeldung vermerken."
export const TeilnahmeLabel = "Teilnahme unbekannt"
export const TeilnahmeFormLabel = "Teilnahmeform unbekannt"
export const ConfirmCheckboxChange = "Sind Sie sicher, dass das Mitglied nicht automatisch zu jeder Sitzung eingeladen werden soll? Sie können das Mitglied dann für die einzelne Sitzung im Tab Einladen und Nachladen manuell hinzufügen."
export const DateInvalidErrorMessage = "Ungültiges Zeitformat. Bitte verwenden Sie das 24-Stunden-Format HH:MM."
export const DateNotInRangeErrorMessage = "Die Zeit kann nicht in der Vergangenheit liegen"
export const GenericApiFailMessage = "Bitte entschuldigen Sie. Hier hat etwas nicht funktioniert. Sollte das Problem erneut auftreten, wenden Sie sich bitte an unseren support: betriebsrat360@ifb.de"
export const KanalLimitMessage = "Ihr Team erreicht bald das Limit von maximal 1000 Teamskanälen. Bitte lassen Sie sich von Ihrer IT einen neuen Teamsbereich anlegen und ändern Sie den zugehörigen Teams-Bereich in der Konfiguration ab."