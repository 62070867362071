import { Spinner, MessageBar } from "@fluentui/react-components";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { LoaderMessage } from "../../constants/textLabels";
import {
  deleteGremiumAdministrators,
  deleteGremiumAssitents,
  getAllGremiumAdministrators,
  getAllGremiumAssitents,
  getAllGremiumKonfigs,
  getGremiumAdministrators,
  getGremiumKonfig,
  patchGremiumKonfig,
  postGremiumAdministrator,
  postGremiumAssitents,
} from "../../api/konfiguration-api";
import {
  getAllUsersAsyncFirstPage,
  getAllUsersAsyncNextLink,
  getUserProfilesAsync,
} from "../../api/users";
// import StatusBar from "../../components/common/toast-notification/toast-notification";
import Constants, { Navigation, Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import IGraphUser from "../../models/konfiguration/graph-user-konfig";
import { getJoinedTeams } from "../../api/teams-api";
import { KonfigurationGremiumForm } from "./konfiguration-gremium-form/konfiguration-gremium-form";
import {
  IKonfigurationGremiumProps,
  IKonfigurationGremiumState,
} from "../../interfaces/konfiguration/konfiguration.interface";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import ConfirmTeamDialog from "../common/dialog/team-change-dialog";
import IAdministrator from "../../models/konfiguration/administrator";
import { getAllMitgliedersFromParent } from "../../api/konfig-mitglieder-api";
import { handleTokenAccessFailure } from "../../Helpers/apiHelper";
import { getAuschusse } from "../../api/gremium-details-api";
import { InfoIcon } from "../../utils/icons";
import IAssistent from "../../models/konfiguration/assistent";
import { GremiumTypes } from "../../enums/gremiumTypes.enum";
import { mergeArraysWithoutDuplicatesID } from "../../utils/utility";
import { userObject } from "./konfiguration-gremium.types";

class KonfigurationGremium extends React.Component<
  IKonfigurationGremiumProps,
  IKonfigurationGremiumState
> {
  theme: string = Themes.default;
  dialogMessage =
    "Die Verkleinerung der Gremiumsgröße kann zur automatischen Entfernung von Gremiumsmitgliedern führen!";

  constructor(props: any) {
    super(props);
    this.state = {
      assistents: [],
      typingTimer: undefined,
      isLoading: false,
      adminDeleteDialogOpen: false,
      updatedSelectedAdministrators: [],
      updatedSelectedAssistents: [],
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      initialGremium: {
        id: Guid.createEmpty(),
        gremienname: "",
        anzahlMitglieder: 0,
        anzahlErsatzmitglieder: 0,
        anzahlJAV: 0,
        anzahlSBV: 0,
        assistenzUserId: Guid.parse(Guid.EMPTY),
        hatAssistenzVollzugriff: false,
        // administratorUsers: [],
        defaultTeamId: Guid.parse(Guid.EMPTY),
        showDauerOnTagesordnung: false,
        showHasAttachmentsOnTagesordnung: false,
        showResponsiblePersonOnTagesordnung: false,
        protokollname: "",
        tagesordnungsname: "",
        parentGremiumId: Guid.createEmpty(),
        ortFiliale: "",
        firmenname: "",
        brMail: "",
        anwesenheitslistenname: "",
        type: GremiumTypes.Betriebsrat,
        isFirstUse: false
      },
      updateGremium: {
        id: Guid.createEmpty(),
        gremienname: "",
        anzahlMitglieder: 0,
        anzahlErsatzmitglieder: 0,
        anzahlJAV: 0,
        anzahlSBV: 0,
        assistenzUserId: Guid.parse(Guid.EMPTY),
        hatAssistenzVollzugriff: false,
        // administratorUsers: [],
        defaultTeamId: Guid.parse(Guid.EMPTY),
        showDauerOnTagesordnung: false,
        showHasAttachmentsOnTagesordnung: false,
        showResponsiblePersonOnTagesordnung: false,
        protokollname: "",
        tagesordnungsname: "",
        parentGremiumId: Guid.createEmpty(),
        ortFiliale: "",
        firmenname: "",
        brMail: "",
        anwesenheitslistenname: "",
        type: GremiumTypes.Betriebsrat,
        isFirstUse: false
      },
      memberCountDecreaseAccepted: true,
      adminUsers: [],
      assistanceUsers: [],
      teams: [],
      userValue: {
        id: Guid.parse(Guid.EMPTY),
        displayName: "",
        isActive: false,
      },
      notification: { id: 0, message: "", type: ActivityStatus.None },
      selectedAdministrators: [],
      administratorObject: [],
      selectedAssistents: [],
      assistentObject: [],
      adminDropdownRef: React.createRef(),
      teamDisplayName: "",
      configDataChanged: false,
      currentLoggedUserId: "",
      confirmDialogRef: React.createRef(),
      confirmTeamDialogRef: React.createRef(),
      confirmDeleteAdministratorUser: false,
      selectedAdminUserId: "",
      selectedAssistentUserId: "",
      gremiumList: [],
      gremiumNameExists: false,
      nextLink: "",
    };

    sessionStorage.setItem("configDataChanged", "false");
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.loadTeams();
  }

  configDataChange = (e) => {
    if (
      e &&
      e.target ===
        this.state.adminDropdownRef.current.querySelector(
          ".ui-dropdown__searchinput__input"
        )
    ) {
      return;
    }

    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  loadTeams = async () => {
    const response = await getJoinedTeams(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      this.setState({ teams: response.data }, this.loadGremiumAsync);
    }
    localStorage.setItem("selectedAuschuss", this.props.auschuss.gremiumId);
  };

  loadGremiumAsync = async () => {
    const resp = await getAuschusse(handleTokenAccessFailure);
    const allAuschusse = resp.data;
    let response = await getGremiumKonfig(
      this.props.auschuss.gremiumId,
      this.handleTokenAccessFailure
    );
    if (response.status === StatusCodes.OK && response.data) {
      this.setState(
        {
          initialGremium: response.data,
        },
        () => this.setAdminObject()
      );
      this.setState({
        updateGremium: response.data,
        gremiumList: allAuschusse,
      });
    }
  };

  loadMoreUsers = async () => {
    if (this.state.nextLink === null) return;
    const allMitglieders = await getAllUsersAsyncNextLink(
      handleTokenAccessFailure,
      this.state.nextLink
    );
    const mergedAdmins = mergeArraysWithoutDuplicatesID(
      this.state.adminUsers,
      allMitglieders.data.items
    );
    const mergedAssistanceUsers = mergeArraysWithoutDuplicatesID(
      this.state.assistanceUsers,
      allMitglieders.data.items
    );
    this.setState({
      adminUsers: mergedAdmins,
      assistanceUsers: mergedAssistanceUsers,
      nextLink: allMitglieders.data.nextLink,
    });
    return {
      assistanceUsers: mergedAssistanceUsers,
      adminUsers: mergedAdmins,
      nextLink: allMitglieders.data.nextLink,
    };
  };

  setAdminObject = async () => {
    const assistentsData: IGraphUser[] = [];

    const respAdmins = await getAllGremiumAdministrators(
      this.handleTokenAccessFailure
    );
    const admins: IAdministrator[] = respAdmins.data.filter(
      (admin) => admin.gremiumId === this.props.auschuss.gremiumId
    );
    let administratorIds = admins
      .map((a) => (a.graphUserId ? a.graphUserId.toString() : null))
      .filter((a): a is string => !!a);
    const currentLoggedUserId = this.props.teamsContext?.user?.id;
    //Added current Logged User ID to add to the admins that are merged later
    const administratorUsers = await this.searchForUsersByIDS([
      ...administratorIds,
      currentLoggedUserId,
    ]);

    const respAssistents = await getAllGremiumAssitents(
      this.handleTokenAccessFailure
    );
    const assistents: IAssistent[] = respAssistents.data.filter(
      (a) => a.gremiumId === this.props.auschuss.gremiumId
    );
    const assistentIds = assistents
      .map((a) => (a.graphUserId ? a.graphUserId.toString() : null))
      .filter((a): a is string => !!a);
    const assistentUsers = await this.searchForUsersByIDS(assistentIds);

    if (
      !this.state.initialGremium.parentGremiumId ||
      this.state.initialGremium.parentGremiumId ===
        Guid.createEmpty().toString()
    ) {
      const allMitglieders = await getAllUsersAsyncFirstPage(
        handleTokenAccessFailure,
        ""
      );
      const additionalUsers = mergeArraysWithoutDuplicatesID(
        assistentUsers || [],
        administratorUsers || []
      );
      const mergedMitglieders = mergeArraysWithoutDuplicatesID(
        allMitglieders.data.items,
        additionalUsers
      );
      mergedMitglieders.forEach((u) => assistentsData.push(u));
      this.setState(
        {
          adminUsers: mergedMitglieders,
          assistanceUsers: assistentsData,
          administratorObject: admins,
          assistentObject: assistents,
          nextLink: allMitglieders.data.nextLink,
        },
        () => {
          this.populateAdministratorUserNames(administratorUsers);
          this.populateAssistentUserName(assistentUsers);
          this.populateTeamDisplayName();
          this.getCurrentUser();
        }
      );
    } else {
      const allMitglieders = await getAllMitgliedersFromParent(
        this.state.initialGremium.parentGremiumId!.toString(),
        handleTokenAccessFailure
      );
      const allMitgliederIDS = allMitglieders.data.map((d) => d.graphUserId);
      const allMitgliederUsers = await this.searchForUsersByIDS(
        allMitgliederIDS
      );
      const additionalUsers = mergeArraysWithoutDuplicatesID(
        assistentUsers || [],
        administratorUsers || []
      );
      const mergedMitglieders = mergeArraysWithoutDuplicatesID(
        allMitgliederUsers!,
        additionalUsers
      );
      mergedMitglieders.forEach((u) => {
        assistentsData.push(u);
      });
      this.setState(
        {
          adminUsers: mergedMitglieders,
          assistanceUsers: assistentsData,
          administratorObject: admins,
          assistentObject: assistents,
        },
        () => {
          this.populateAdministratorUserNames(administratorUsers);
          this.populateAssistentUserName(assistentUsers);
          this.populateTeamDisplayName();
          this.getCurrentUser();
        }
      );
    }
  };

  getCurrentUser = () => {
    const currentLoggedUserId = this.props.teamsContext?.user?.id;
      this.setState({ currentLoggedUserId: currentLoggedUserId });
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  searchForUsersByIDS = async (ids: string[]): Promise<IGraphUser[] | null> => {
    const emptyGuid = Guid.createEmpty().toString();
    const filteredUserIds = ids.filter((id) => id && id !== emptyGuid);

    let users: IGraphUser[] | null = null;
    if (filteredUserIds.length === 0) {
      users = [];
      return users;
    }

    const response = await getUserProfilesAsync(
      filteredUserIds,
      handleTokenAccessFailure
    );
    if (response.status === 200) {
      users = response.data;
    }
    return users;
  };

  searchForUsers = async (
    searchTerm: string
  ): Promise<{ users: IGraphUser[]; nextLink: string }> => {
    let users: IGraphUser[] = [];
    const firstPageResponse = await getAllUsersAsyncFirstPage(
      handleTokenAccessFailure,
      searchTerm
    );
    if (firstPageResponse.status === 200) {
      users.push(...firstPageResponse.data.items);
    }
    return { users: users, nextLink: firstPageResponse.data.nextLink };
  };

  searchForUsersWithNextLink = async (
    nextLink: string
  ): Promise<{ users: IGraphUser[]; nextLink: string | null }> => {
    let users: IGraphUser[] = [];
    const nextPageResponse = await getAllUsersAsyncNextLink(
      handleTokenAccessFailure,
      nextLink
    );
    if (nextPageResponse.status === 200) {
      users.push(...nextPageResponse.data.items);
    }
    return { users: users, nextLink: nextPageResponse.data.nextLink };
  };

  populateAdministratorUserNames = async (
    administratorUsers: IGraphUser[] | null
  ) => {
    if (administratorUsers) {
      if (this.state.administratorObject) {
        const administratorIds = administratorUsers.map(a => a.id.toString())
        this.setState({
          selectedAdministrators: administratorIds,
        });
      }
    }
    this.setState({ isLoading: false });
  };

  populateAssistentUserName = async (assistentUsers: IGraphUser[] | null) => {
    if (assistentUsers) {
      if (this.state.assistentObject) {
        const asistentIds = assistentUsers.map(as => as.id.toString())
        this.setState({
          selectedAssistents: asistentIds,
        });
      }
    }
    this.setState({ isLoading: false });
  };

  populateTeamDisplayName = () => {
    const teamId = this.state.initialGremium.defaultTeamId;
    const teamDisplayName = this.state.teams.find(
      (t) => t.id === teamId
    )?.displayName;

    this.setState({
      teamDisplayName: teamDisplayName,
    });
  };

  onGremiennameChange = async (event: any) => {
    let gremienname = event.target.value;

    this.setState(
      (prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, gremienname },
        updateGremium: { ...prevState.initialGremium, gremienname },
      }),
      () => {
        if (this.state.typingTimer) {
          clearTimeout(this.state.typingTimer);
        }
        this.setState({
          typingTimer: setTimeout(async () => {
            if (!this.state.gremiumList.find((g) => g.name === gremienname)) {
              this.setState({ gremiumNameExists: false });
              await patchGremiumKonfig(
                this.props.auschuss.gremiumId,
                "gremienname",
                gremienname,
                this.handleTokenAccessFailure
              );
              const resp = await getAuschusse(handleTokenAccessFailure);
              const allAuschusse = resp.data;
              this.setState({ gremiumList: allAuschusse });
            } else {
              this.setState({ gremiumNameExists: true });
            }
          }, 1000),
        });
      }
    );
  };

  onFirmenameChange = async (event: any) => {
    let firmenname = event.target.value;

    this.setState(
      (prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, firmenname },
        updateGremium: { ...prevState.initialGremium, firmenname },
      }),
      () => {
        if (this.state.typingTimer) {
          clearTimeout(this.state.typingTimer);
        }
        this.setState({
          typingTimer: setTimeout(async () => {
            await patchGremiumKonfig(
              this.props.auschuss.gremiumId,
              "firmenname",
              firmenname,
              this.handleTokenAccessFailure
            );
          }, 1000),
        });
      }
    );
  };

  onOrtFilialeChange = async (event: any) => {
    let ortFiliale = event.target.value;

    this.setState(
      (prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, ortFiliale },
        updateGremium: { ...prevState.initialGremium, ortFiliale },
      }),
      () => {
        if (this.state.typingTimer) {
          clearTimeout(this.state.typingTimer);
        }
        this.setState({
          typingTimer: setTimeout(async () => {
            await patchGremiumKonfig(
              this.props.auschuss.gremiumId,
              "ortFiliale",
              ortFiliale,
              this.handleTokenAccessFailure
            );
          }, 1000),
        });
      }
    );
  };

  onMitgliederChangeWrapper = (event: any) => {
    let anzahlMitglieder: number = parseInt(event.target.value);
    let prevValue = this.state.initialGremium.anzahlMitglieder;

    if (
      anzahlMitglieder === null ||
      anzahlMitglieder < 1 ||
      Number.isNaN(anzahlMitglieder)
    ) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, prevValue },
        updateGremium: { ...prevState.initialGremium, prevValue },
      }));
      return;
    }

    if (prevValue > anzahlMitglieder) {
      this.state.confirmDialogRef.current.showConfirmDialog(
        this.dialogMessage,
        () => this.onMitgliederChange(anzahlMitglieder)
      );
    } else {
      this.onMitgliederChange(anzahlMitglieder);
    }
  };

  onMitgliederChange = async (anzahlMitglieder: number) => {
    await patchGremiumKonfig(
      this.props.auschuss.gremiumId,
      "anzahlMitglieder",
      anzahlMitglieder,
      this.handleTokenAccessFailure
    );
    this.setState((prevState: IKonfigurationGremiumState) => ({
      initialGremium: { ...prevState.initialGremium, anzahlMitglieder },
      updateGremium: { ...prevState.initialGremium, anzahlMitglieder },
    }));
  };

  onErsatzmitgliederChangeWrapper = (event: any) => {
    let anzahlErsatzmitglieder: number = parseInt(event.target.value);
    let prevValue = this.state.initialGremium.anzahlErsatzmitglieder;

    if (
      anzahlErsatzmitglieder === null ||
      anzahlErsatzmitglieder < 0 ||
      Number.isNaN(anzahlErsatzmitglieder)
    ) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, prevValue },
        updateGremium: { ...prevState.initialGremium, prevValue },
      }));
      return;
    }

    if (prevValue > anzahlErsatzmitglieder) {
      this.state.confirmDialogRef.current.showConfirmDialog(
        this.dialogMessage,
        () => this.onErsatzmitgliederChange(anzahlErsatzmitglieder)
      );
    } else {
      this.onErsatzmitgliederChange(anzahlErsatzmitglieder);
    }
  };

  onErsatzmitgliederChange = async (anzahlErsatzmitglieder: number) => {
    await patchGremiumKonfig(
      this.props.auschuss.gremiumId,
      "anzahlErsatzmitglieder",
      anzahlErsatzmitglieder,
      this.handleTokenAccessFailure
    );
    this.setState((prevState: IKonfigurationGremiumState) => ({
      initialGremium: { ...prevState.initialGremium, anzahlErsatzmitglieder },
      updateGremium: { ...prevState.initialGremium, anzahlErsatzmitglieder },
    }));
  };

  onJAVChangeWrapper = (event: any) => {
    let anzahlJAV: number = parseInt(event.target.value);
    let prevValue = this.state.initialGremium.anzahlJAV;

    if (anzahlJAV === null || anzahlJAV < 0 || Number.isNaN(anzahlJAV)) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, prevValue },
        updateGremium: { ...prevState.initialGremium, prevValue },
      }));
      return;
    }

    if (prevValue > anzahlJAV) {
      this.state.confirmDialogRef.current.showConfirmDialog(
        this.dialogMessage,
        () => this.onJAVChange(anzahlJAV)
      );
    } else {
      this.onJAVChange(anzahlJAV);
    }
  };

  onJAVChange = async (anzahlJAV: number) => {
    await patchGremiumKonfig(
      this.props.auschuss.gremiumId,
      "anzahlJAV",
      anzahlJAV,
      this.handleTokenAccessFailure
    );
    this.setState((prevState: IKonfigurationGremiumState) => ({
      initialGremium: { ...prevState.initialGremium, anzahlJAV },
      updateGremium: { ...prevState.initialGremium, anzahlJAV },
    }));
  };

  onSBVChangeWrapper = (event: any) => {
    let anzahlSBV: number = parseInt(event.target.value);
    let prevValue = this.state.initialGremium.anzahlSBV;

    if (anzahlSBV === null || anzahlSBV < 0 || Number.isNaN(anzahlSBV)) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: { ...prevState.initialGremium, prevValue },
        updateGremium: { ...prevState.initialGremium, prevValue },
      }));
      return;
    }

    if (prevValue > anzahlSBV) {
      this.state.confirmDialogRef.current.showConfirmDialog(
        this.dialogMessage,
        () => this.onSBVChange(anzahlSBV)
      );
    } else {
      this.onSBVChange(anzahlSBV);
    }
  };

  onSBVChange = async (anzahlSBV: number) => {
    await patchGremiumKonfig(
      this.props.auschuss.gremiumId,
      "anzahlSBV",
      anzahlSBV,
      this.handleTokenAccessFailure
    );
    this.setState((prevState: IKonfigurationGremiumState) => ({
      initialGremium: { ...prevState.initialGremium, anzahlSBV },
      updateGremium: { ...prevState.initialGremium, anzahlSBV },
    }));
  };

  onGremiumMailChange = async (event: any) => {
    let gremiumMail = event.target.value;
    await patchGremiumKonfig(
      this.props.auschuss.gremiumId,
      "brMail",
      gremiumMail,
      this.handleTokenAccessFailure
    );
    this.setState((prevState: IKonfigurationGremiumState) => ({
      initialGremium: { ...prevState.initialGremium, brMail: gremiumMail },
      updateGremium: { ...prevState.initialGremium, brMail: gremiumMail },
    }));
  };

  // onAssistenzChange = async (_, v) => {
  //   const assitentUserName = v.optionText;
  //   let assistenzUserId = assitentUserName
  //     ? this.state.adminUsers.find((u) => u.displayName === v.optionValue)?.id
  //     : Guid.createEmpty();

  //   if (!assistenzUserId) {
  //     assistenzUserId = Guid.createEmpty()
  //   }
  //   await patchGremiumKonfig(this.props.auschuss.gremiumId, 'assistenzUserId', assistenzUserId?.toString(), this.handleTokenAccessFailure)
  //   this.setState((prevState: IKonfigurationGremiumState) => ({
  //     initialGremium: { ...prevState.initialGremium, assistenzUserId },
  //     updateGremium: { ...prevState.initialGremium, assistenzUserId },
  //     assistentUserName: assitentUserName,
  //   }));

  //   this.configDataChange(null);
  // };

  onTeamChange = (_, v) => {
    const message =
      "Achtung! Sind Sie sicher, dass Sie den Teamsbereich ändern möchten? Damit werden Sitzungseinladungen und Dateien zukünftig in einem anderem Teamsbereich mit den dazugehörigen Berechtigungen erstellt.";
    this.state.confirmTeamDialogRef.current.showConfirmTeamDialog(
      message,
      async () => {
        const teamDisplayName = v.optionText;
        const defaultTeamId = teamDisplayName
          ? this.state.teams.find((t) => t.displayName === teamDisplayName)?.id
          : undefined;

        if (defaultTeamId) {
          await patchGremiumKonfig(
            this.props.auschuss.gremiumId,
            "defaultTeamId",
            defaultTeamId,
            this.handleTokenAccessFailure
          );
        }
        this.setState((prevState: IKonfigurationGremiumState) => ({
          initialGremium: { ...prevState.initialGremium, defaultTeamId },
          updateGremium: { ...prevState.initialGremium, defaultTeamId },
          teamDisplayName: teamDisplayName,
        }));
        this.configDataChange(null);
      }
    );
  };

  updateAdministratorUserNames = () => {
    const newSelectedAdministrators = this.state.updatedSelectedAdministrators;
    if (
      newSelectedAdministrators?.length === 0 ||
      !newSelectedAdministrators.includes(this.state.currentLoggedUserId)
    )
      return;

    if (newSelectedAdministrators) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: {
          ...prevState.initialGremium,
          administratorUsers: newSelectedAdministrators,
        },
        updateGremium: {
          ...prevState.initialGremium,
          administratorUsers: newSelectedAdministrators,
        },
        selectedAdministrators: newSelectedAdministrators,
      }));
      this.configDataChange(null);
    }
  };
  updateAssistentUserNames = () => {
    const newSelectedAssistents = this.state.selectedAssistents;
    if (
      newSelectedAssistents?.length === 0 ||
      !newSelectedAssistents.includes(this.state.currentLoggedUserId)
    )
      return;

    if (newSelectedAssistents) {
      this.setState((prevState: IKonfigurationGremiumState) => ({
        initialGremium: {
          ...prevState.initialGremium,
          assistanceUsers: newSelectedAssistents,
        },
        updateGremium: {
          ...prevState.initialGremium,
          assistanceUsers: newSelectedAssistents,
        },
        selectedAssistents: newSelectedAssistents,
      }));
      this.configDataChange(null);
    }
  };

  isFormValid = () => {
    return (
      this.state.gremiumList.find(
        (g) => g.name === this.state.initialGremium.gremienname
      ) &&
      this.state.initialGremium.gremienname.trim() !== "" &&
      this.state.initialGremium.firmenname.trim() !== "" &&
      this.state.initialGremium.brMail.trim() !== "" &&
      this.state.teamDisplayName !== null &&
      this.state.teamDisplayName !== undefined &&
      this.state.selectedAdministrators.length > 0
    );
  };

  confirmDecreasingMemberCountWrapper = () => {
    this.setState({
      memberCountDecreaseAccepted: false,
    });
    const dialogMessage =
      "Die Verkleinerung der Gremiumsgröße kann zur automatischen Entfernung von Gremiumsmitgliedern führen!";
    this.state.confirmDialogRef.current.showConfirmDialog(dialogMessage);
  };

  confirmDecreasingMemberCount = () => {
    this.setState({
      memberCountDecreaseAccepted: true,
    });

    return true;
  };

  async componentDidUpdate(
    prevProps: Readonly<IKonfigurationGremiumProps>,
    prevState: Readonly<IKonfigurationGremiumState>,
    snapshot?: any
  ) {
    if (
      prevState.confirmDeleteAdministratorUser !==
      this.state.confirmDeleteAdministratorUser
    ) {
      const findUserId = this.state.adminUsers.find((user) =>
        user.id.toString() === this.state.selectedAdminUserId
      );
      if (findUserId) {
        const findId = this.state.administratorObject.find(
          (a) => a.graphUserId === findUserId.id
        );
        if (this.state.confirmDeleteAdministratorUser && findId) {
          await deleteGremiumAdministrators(
            findId!.id.toString(),
            this.handleTokenAccessFailure
          );
          const filteredAdministartors = this.state.administratorObject.filter(
            (o) => o.graphUserId !== findId.graphUserId
          );
          this.setState({
            confirmDeleteAdministratorUser: false,
            administratorObject: filteredAdministartors,
          });
        }
      }
    }
  }

  onGremiumAdministratorChange = async (_, userValue: userObject) => {
    if (this.props.userHasUpdatePermission !== true) {
      return;
    }

    this.setState({ selectedAdminUserId: userValue.id.toString() });
    const findUserId = userValue.newUsers.find((user) =>
      user.id === userValue.id
    );

    // This means that it is deleting an item
    if (
      userValue.selectedOptions.length <
      this.state.selectedAdministrators?.length
    ) {
      this.setState({
        adminDeleteDialogOpen: true,
        updatedSelectedAdministrators: userValue.selectedOptions,
      });
    } else {
      if (findUserId) {
        const admin: IAdministrator = {
          id: Guid.createEmpty().toString(),
          graphUserId: findUserId.id,
          gremiumId: this.props.auschuss.gremiumId,
        };
        if (
          this.state.selectedAdministrators.find(
            (user) => user === findUserId.displayName
          ) === undefined
        ) {
          const resp = await postGremiumAdministrator(
            admin,
            this.handleTokenAccessFailure
          );
          this.state.administratorObject.push(resp.data);
        }
      }
      this.setState(
        {
          updatedSelectedAdministrators: userValue.selectedOptions,
          adminUsers: userValue.newUsers,
        },
        this.updateAdministratorUserNames
      );
    }
  };
  onGremiumAssistentChange = async (_, userValue: userObject) => {
    if (this.props.userHasUpdatePermission !== true) {
      return;
    }

    this.setState({ selectedAssistentUserId: userValue.id.toString() });
    const findUserId = userValue.newUsers.find((user) =>
      user.id === userValue.id
    );
    // This means that it is deleting an item
    if (
      userValue.selectedOptions.length < this.state.selectedAssistents?.length
    ) {
      this.setState({
        selectedAssistents: userValue.selectedOptions,
      });
      if (findUserId) {
        const findId = this.state.assistentObject.find(
          (a) => a.graphUserId === findUserId.id
        );
        if (findId) {
          await deleteGremiumAssitents(
            findId.id.toString(),
            handleTokenAccessFailure
          );
          const filteredState = this.state.assistentObject.filter(
            (o) => o.graphUserId !== findId.graphUserId
          );
          this.setState({ assistentObject: filteredState });
        }
      }
    } else {
      if (findUserId) {
        const assistent: IAssistent = {
          id: Guid.createEmpty().toString(),
          graphUserId: findUserId.id,
          gremiumId: this.props.auschuss.gremiumId,
        };
        if (
          this.state.selectedAssistents.find(
            (user) => user === findUserId.displayName
          ) === undefined
        ) {
          const resp = await postGremiumAssitents(
            assistent,
            this.handleTokenAccessFailure
          );
          this.state.assistentObject.push(resp.data);
        }
      }
      this.setState(
        {
          selectedAssistents: userValue.selectedOptions,
          assistanceUsers: userValue.newUsers,
        },
        this.updateAssistentUserNames
      );
    }
  };

  onButtonNext = () => {
    this.props.navMenuItemClicked(Navigation.Mitglieder);
  };

  render = () => {
    return (
      <div className="container-div">
        <div>
          {/* <StatusBar notification={this.state.notification} /> */}
          <div className="">
            <div className="konfig-box">
              <MessageBar
                icon={<InfoIcon />}
                intent="info"
                style={{
                  width: "100%",
                  textAlign: "left",
                  padding: "20px 30px",
                  margin: "10px 0 20px 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                Besonders wichtig ist, dass Sie sorgfältig das Standard-Team
                auswählen, in dem später alle Sitzungen als Kanäle angelegt
                werden. Achten Sie hierbei unbedingt darauf, dass nur feste
                Gremiumsmitglieder zu dem Team hinzugefügt sind.
              </MessageBar>
            </div>
            {this.state.isLoading ? (
              <Spinner
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "fixed",
                  top: "0",
                  left: "0",
                }}
                className="loader"
                label={LoaderMessage}
              />
            ) : (
              <KonfigurationGremiumForm
                adminDeleteDialogOpen={this.state.adminDeleteDialogOpen}
                adminDropdownRef={this.state.adminDropdownRef}
                selectedAdministrators={this.state.selectedAdministrators}
                selectedAsistents={this.state.selectedAssistents}
                onTeamChange={this.onTeamChange}
                teamDisplayName={this.state.teamDisplayName}
                configDataChange={this.configDataChange}
                configDataChanged={this.state.configDataChanged}
                currentLoggedUserId={this.state.currentLoggedUserId}
                adminUsers={this.state.adminUsers}
                assistanceUsers={this.state.assistanceUsers}
                initialGremium={this.state.initialGremium}
                isLoading={this.state.isLoading}
                onGremiumAssistentChange={this.onGremiumAssistentChange}
                onDataUpdate={() => {}}
                onErsatzmitgliederChange={this.onErsatzmitgliederChangeWrapper}
                onGremiennameChange={this.onGremiennameChange}
                onGremiumMailChange={this.onGremiumMailChange}
                onJAVChange={this.onJAVChangeWrapper}
                onMitgliederChange={this.onMitgliederChangeWrapper}
                onOrtFilialeChange={this.onOrtFilialeChange}
                onSBVChange={this.onSBVChangeWrapper}
                onFirmenameChange={this.onFirmenameChange}
                setAdminDeleteDialogOpen={(value) =>
                  this.setState({ adminDeleteDialogOpen: value })
                }
                teams={this.state.teams}
                userHasUpdatePermission={this.props.userHasUpdatePermission}
                isKonfigurationGremium={true}
                updateAdministratorUserNames={this.updateAdministratorUserNames}
                updateAssistentUserNames={this.updateAssistentUserNames}
                onGremiumAdministratorChange={this.onGremiumAdministratorChange}
                updatedAdministratorUserNames={
                  this.state.updatedSelectedAdministrators
                }
                updatedAssitentUserNames={this.state.updatedSelectedAssistents}
                isFormValid={this.isFormValid()}
                isCreateNewAuschuss={false}
                navMenuItemClicked={this.onButtonNext}
                setDeleteAdmin={(value) =>
                  this.setState({ confirmDeleteAdministratorUser: value })
                }
                gremiumNameExists={this.state.gremiumNameExists}
                isAuschuss={false}
                loadMoreUsers={this.loadMoreUsers}
                nextLink={this.state.nextLink}
                searchForUsers={this.searchForUsers}
                searchForUsersNextLink={this.searchForUsersWithNextLink}
              />
            )}
          </div>
        </div>
        <ConfirmDialog ref={this.state.confirmDialogRef} />
        <ConfirmTeamDialog ref={this.state.confirmTeamDialogRef} />
      </div>
    );
  };
}

export default KonfigurationGremium;
